export const CHARTCONSTANTS = {
  chartType: {
    pieChart: {
      type: 'pieChart',
      donut: false,
      colors:['#1395BA', '#117899', '#0F5B78', '#0D3C55', '#C02E1D', '#D94E1F', '#F16C20', '#EF8B2C', '#ECAA38', '#EBC844', '#A2B86C', '#5CA793']
    },
    lineChart: {
      type: 'lineChart',
      donut: false,
      colors: [ '#32639c' ,'#f5a623'] ,
    },
    areaChart: {
      type: 'lineChart',
      donut: false,
      colors: ['#32639c'] ,
    },
    donutChart: {
      type: 'pieChart',
      donut: true,
      colors:['#01508C','#32639C','#5077AC','#6A8CBC','#84A1CD','#9DB6DD','#B7CDEE','#D1E3FF']
     },
    discreteBarChart: {
      type: 'discreteBarChart',
      donut: true,
      colors: ['#01508c'] ,
    },
    multiBarChart: {
      type: 'multiBarChart',
      donut: false, 
      colors:['#01508C', '#5077AC' ,'#B7CDEE'] ,
    },
  },
 
  height :350,
  width:500
}

import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MatLabel} from './label';
export {MatLabel} from './label';
@NgModule({
  declarations: [MatLabel], 
  exports: [MatLabel], imports: [CommonModule], entryComponents: [MatLabel]})
export class MatLabelModule {
  static forRoot(): ModuleWithProviders { return {ngModule: MatLabelModule, providers: []}; }
}

export const DashboardConstant: object = {
	'B2C_LASTMILE_FWD': ['can_get_city_stats'],
	'B2C_LASTMILE_RET': ['can_get_city_stats'],
	'B2C_LASTMILE_RVP': ['can_get_city_stats'],
	'B2C_FIRSTMILE_FWD': ['can_get_city_stats'],
	'B2C_FIRSTMILE_RET': ['can_get_city_stats'],
	'B2C_FIRSTMILE_RVP': ['can_get_city_stats'],

	'B2B_FIRSTMILE_FWD': ['can_get_city_stats'],
	'B2B_FIRSTMILE_RET': ['can_get_city_stats'],
	'B2B_FIRSTMILE_RVP': ['can_get_city_stats'],
	'B2B_LASTMILE_FWD': ['can_get_city_stats'],
	'B2B_LASTMILE_RET': ['can_get_city_stats'],
	'B2B_LASTMILE_RVP': ['can_get_city_stats'],
	'DOWNLOAD_B2C_LASTMILE_FWD': ['can_download_full_mappings'],
	'DOWNLOAD_B2C_LASTMILE_RET': ['can_download_full_mappings'],
	'DOWNLOAD_B2C_LASTMILE_RVP': ['can_download_full_mappings'],
	'DOWNLOAD_B2C_FIRSTMILE_FWD': ['can_download_full_mappings'],
	'DOWNLOAD_B2C_FIRSTMILE_RET': ['can_download_full_mappings'],
	'DOWNLOAD_B2C_FIRSTMILE_RVP': ['can_download_full_mappings'],

	'DOWNLOAD_B2B_FIRSTMILE_FWD': ['can_download_full_mappings'],
	'DOWNLOAD_B2B_FIRSTMILE_RET': ['can_download_full_mappings'],
	'DOWNLOAD_B2B_FIRSTMILE_RVP': ['can_download_full_mappings'],
	'DOWNLOAD_B2B_LASTMILE_FWD': ['can_download_full_mappings'],
	'DOWNLOAD_B2B_LASTMILE_RET': ['can_download_full_mappings'],
	'DOWNLOAD_B2B_LASTMILE_RVP': ['can_download_full_mappings'],
	'UPLOAD_B2C_LASTMILE_FWD': ['BtoC_lm_fwd_can_upload_mappings'],
	'UPLOAD_B2C_LASTMILE_RET': ['BtoC_lm_ret_can_upload_mappings'],
	'UPLOAD_B2C_LASTMILE_RVP': ['BtoC_lm_rvp_can_upload_mappings'],
	'UPLOAD_B2C_FIRSTMILE_FWD': ['BtoC_fm_fwd_can_upload_mappings'],
	'UPLOAD_B2C_FIRSTMILE_RET': ['BtoC_fm_ret_can_upload_mappings'],
	'UPLOAD_B2C_FIRSTMILE_RVP': ['BtoC_fm_rvp_can_upload_mappings'],

	'UPLOAD_B2B_FIRSTMILE_FWD': ['BtoB_fm_fwd_can_upload_mappings'],
	'UPLOAD_B2B_FIRSTMILE_RET': ['BtoB_fm_ret_can_upload_mappings'],
	'UPLOAD_B2B_FIRSTMILE_RVP': ['BtoB_fm_rvp_can_upload_mappings'],
	'UPLOAD_B2B_LASTMILE_FWD': ['BtoB_lm_fwd_can_upload_mappings'],
	'UPLOAD_B2B_LASTMILE_RET': ['BtoB_lm_ret_can_upload_mappings'],
	'UPLOAD_B2B_LASTMILE_RVP': ['BtoB_lm_rvp_can_upload_mappings']
};

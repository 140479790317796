
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { MatChartService } from './chart.service';
@Component({
  selector: 'mat-chart',
  templateUrl: './chart.html',
  providers: [MatChartService],
  styleUrls: [
    './chart.scss',
    './../../../../../node_modules/nvd3/build/nv.d3.css'
    ],
  encapsulation: ViewEncapsulation.None
})
export class MatChart implements OnInit {
  @Input() options: any;
  @Input() type: string;
  @Input() data: any;
  @Input() colors: any;
  @Input() customClass: any;
  @Input() hideLabelsOptions={};
  
  constructor(private chartService: MatChartService) { }
  ngOnInit() {
    if ((!this.type)) {
      throw new Error('Chart type required.');
    }
    if (!this.options) {
      this.options = this.chartService.getOptions(this.type);
    }
    if (this.colors) {
      this.options.chart.color = (d, i) => {
        return (this.colors && this.colors[i % this.colors.length]);
      }
    }
  }
}
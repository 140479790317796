
/**
 * @description generating config for nvd3 chart
 */

import { Injectable } from '@angular/core';
import { CHARTCONSTANTS } from './chart.constants';


@Injectable()
export class MatChartService {
  options: any;

  constructor() {
    this.options = {
      chart: {
        type: '',
        legendPosition: "bottom",
        height: CHARTCONSTANTS.height,
        width: CHARTCONSTANTS.width,
        margin: {
          top: 20,
          right: 20,
          bottom: 40,
          left: 55
        },
        groupSpacing :0.5, 
        x: function (d) { return d.x; },
        y: function (d) { return d.y; },
        useInteractiveGuideline: false,
        showLabels: true,
        duration: 500,
        labelThreshold: 0.03,
        donut: false,
        labelType: "percent",
        xAxis: {
          ticks: 10,
        },
        growOnHover :false,
        yAxis: {
          ticks: 10,
          tickFormat: function (d) {
            return d3.format('.0')(d);
          },
          axisLabelDistance: -10
        },
        callback: function () {
          d3.selectAll('.nv-pieLabels text').style('fill', 'white');
        },
        stacked: true,
        showControls: false,
        reduceXTicks: false,
        legend: {
          rightAlign: false
          },
      },
    };
  }

  /**
   * @desc returns chart options
   * @param name 
   */
  getOptions(name) {
    this.options.chart.type = CHARTCONSTANTS['chartType'][name] ? CHARTCONSTANTS['chartType'][name].type : '';
    this.options.chart.donut = CHARTCONSTANTS['chartType'][name] ? CHARTCONSTANTS['chartType'][name].donut : '';
    if(!this.options.chart.type){
      return;
    }
    this.options.chart.color = (d, i) => {
      return (CHARTCONSTANTS['chartType'][name].colors[i % CHARTCONSTANTS['chartType'][name].colors.length]);
    }
    return this.options;
  }

}
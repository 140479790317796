import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MatChart} from './chart';
export {MatChart} from './chart';
export {MatChartService} from './chart.service';

import { NvD3Module } from 'ng2-nvd3';
@NgModule({declarations: [MatChart], exports: [MatChart] ,imports :[NvD3Module ,CommonModule ]   , })
export class MatChartModule {
  static forRoot(): ModuleWithProviders { return {ngModule: MatChartModule,  }
}

}

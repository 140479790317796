import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
@Component({
  selector: 'mat-label',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <span [class]="'label label-' + type ">{{label}} 
  </span>`
  ,
  styleUrls: ['./_label.scss']
})
export class MatLabel {

  @Input() type: string;
  @Input() label: string;
  constructor() {
  }

  closeHandler() {  }
}
